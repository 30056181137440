export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const photos = {
  a: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA8CAIAAACrV36WAAAAAXNSR0IArs4c6QAAAARn' + 
  'QU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGnSURBVGhD7dnBbQJBDAVQk1o2QjlQwKYGzpSwKQfq4IxIC' + 
  'RTB9jLZHCJFwWv7/7EiDt6zmX2yPYMHNq01eb7n5flI36JiIXWpbFW2kAwgsdVblS0kA0hs9db/ZWs+vW/Wno9PxPE3dh' + 
  'ls6Od+HI1XT1d64Sb8R5utEulwdbA8VY+LZ/kqkfF456pBHxDz5Xxze/p2vsxukBbAshTVOE0PO4B2cUlWKrgUTKsrV0e' + 
  'ut3RVU/cm5aKKqPXVbjuIDPtDUh2JImq1+jmjkupIFNFStXadHncWXkecpb3393me4oJZnionXyjLV6W4QFZEleHCWNG+' + 
  '0eKggQJiRVV6vhAXwoqrul0AC1H1uuIsTLUyukYH1jBL7WJ8lgq6oqwkVXSQDrLSVEFXjJWoirlCrFRVyBVhJasirgCr6' + 
  '5tEv7a5A5jL0tcN7vNl9OVcHqtXRbocVr+Kc9k3H/3qPL69Ise7dh0SsS+2JmtFddgvdy/gGbY7Jdp2GRcyrlu1BfUjxt' + 
  'iPRm/lqVbGHOMHnU39zQm0I/UbBLA+GVosJHGVrcoWkgEktnoLydYXkF/LiXG21MwAAAAASUVORK5CYII='
};

export async function retrieveData() {
  let res = await fetch('https://bundenavigator-app-server-dot-artemfyi.ew.r.appspot.com/bundestag')
  res = await res.json()

  let data = []
  for (let c of res.committees) {
    let fakeMember = {
      id: 0,
      isVisible: false,
      parent: null,
      label: 'Fake Member',
      description: 'Fake Description',
      image: photos.a,
    }
    for (let i = 0; i < res.members[c.id].length; i++) {
      res.members[c.id][i].uid = res.members[c.id][i].id
      res.members[c.id][i].id = i + 1
      res.members[c.id][i].parent = 0
      res.members[c.id][i].image = res.members[c.id][i].picture_url || photos.a
      res.members[c.id][i].isVisible = true
    }
    c['members'] = [fakeMember, ...res.members[c.id]]
    data.push(c)
  }
  
  return data
}