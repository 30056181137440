import React from 'react';
import './Committee.css';
import Card from './Card';
import { getWindowDimensions } from './utils';
import { OrgDiagram } from 'basicprimitivesreact';
import { PageFitMode, Enabled, ChildrenPlacementType } from 'basicprimitives';
 
function Committee (props) {

  function getLocalItemId (itemConfig) {
    if (itemConfig) {
      for (let i = 0; i < props.members.length; i++) {
        if (props.members[i].title === itemConfig.label) return i;
      }
    }
    return null
  }

  function handleCursorRender ({ context: itemConfig }) {
    if (!props.cursorItemConfig || props.cursorItemConfig.label !== itemConfig.label) {
      props.setCursorItemConfig(itemConfig)
    }
    return <div className="ItemFrame"/>
  }
  
  // function handleHighlightRender ({ context: itemConfig }) {
  //   if (!props.highlightItemConfig || props.highlightItemConfig !== itemConfig) {
  //     props.setHighlightItemConfig(itemConfig)
  //   }
  //   return <div className="ItemFrame"/>
  // }

  function handleItemRender ({ context: itemConfig }) {
    // const tags = []
    // if (props.tags[itemConfig.label]) {
    //   props.tags[itemConfig.label].sort()
    //   props.tags[itemConfig.label].forEach(tag => {
    //     tags.push(<span className="TagSpan">{tag}</span>)
    //   })
    // }
    return <Card
      itemConfig={itemConfig}
      colors={props.colors}
      cursorItemConfig={props.cursorItemConfig}
      committeeRole={props.memberRoles[itemConfig.uid]}
    />;
  }

  function handleButtonsRender ({ context: itemConfig }) {
    // const tags = ['🇺🇦', '🤝']
    // const buttons = []
    // for (let i = 0; i < tags.length; i++) {
    //   buttons.push(
    //     <button key={i} className="StyledButton"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         handleTagClicked(itemConfig, tags[i])
    //       }}>
    //       ±{ tags[i] }
    //     </button>
    //   )
    // }
    const colors = [
      { color: 'rgb(34 197 94)', emoji: '🟢' },
      { color: 'rgb(239 68 68)', emoji: '🔴' },
      { color: 'rgb(249 115 22)', emoji: '🟠' },
    ]
    const buttons = []
    for (let i = 0; i < colors.length; i++) {
      buttons.push(
        <button key={i} className="StyledButton"
          onClick={(event) => {
            event.stopPropagation();
            handleColorClicked(itemConfig, colors[i].color)
          }}>
          { colors[i].emoji }
        </button>
      )
    }
    return <> 
      <button key={99} className="StyledButton font-bold"
          onClick={(event) => {
            event.stopPropagation();
            window.open(itemConfig.abgeordnetenwatch_url);
          }}>
          GO
        </button>
      { buttons } 
    </>
  }

  // function handleTagClicked (itemConfig, tagName) {
  //   let memberTags = props.tags[itemConfig.label]
  //   if (memberTags) {
  //     if (memberTags.includes(tagName)) {
  //       memberTags.splice(memberTags.indexOf(tagName), 1)
  //     } else {
  //       memberTags.push(tagName)
  //     }
  //   } else {
  //     props.tags[itemConfig.label] = [tagName]
  //   }
  //   localStorage.setItem('tags', JSON.stringify(props.tags));
  //   props.setTags(props.tags)
  // }

  function handleColorClicked (itemConfig, color) {
    let memberColor = props.colors[itemConfig.uid]
    if (memberColor) {
      if (memberColor === color) {
        props.colors[itemConfig.uid] = 'rgb(65, 105, 225)'
      } else {
        props.colors[itemConfig.uid] = color
      }
    } else {
      props.colors[itemConfig.uid] = color
    }
    localStorage.setItem('colors', JSON.stringify(props.colors));
    props.setColors(props.colors)
  }

  function handleTitleClicked () {
    if (props.openCommitteeId === props.id) {
      props.setOpenCommitteeId(null)
    } else {
      props.setOpenCommitteeId(props.id)
    }
  }

  function getColumnsByWindowSize () {
    if (getWindowDimensions().width > 1300) {
      return 4
    } else if (getWindowDimensions().width > 1000){
      return 3
    } else if (getWindowDimensions().width > 670) {
      return 2
    }
    return 1
  }

  const config = {
    pageFitMode: PageFitMode.AutoSize,
    leavesPlacementType: ChildrenPlacementType.Matrix,
    childrenPlacementType: ChildrenPlacementType.Matrix,
    maximumColumnsInMatrix: getColumnsByWindowSize(),
    autoSizeMinimum: { width: 100, height: 100 },
    autoSizeMaximum: { width: 1124, height: 90000 },
  
    cursorItem: getLocalItemId(props.cursorItemConfig),
    highlightItem: getLocalItemId(props.highlightItemConfig),

    hasButtons: Enabled.Auto,
    onButtonsRender: handleButtonsRender,
    hasSelectorCheckbox: Enabled.False,
    selectedItems: props.selectedItemConfigs.map(x => getLocalItemId(x)),

    templates: [{
      name: 'syncedCommitteeMember',
      itemSize: { width: 250, height: 154 },
      onItemRender: handleItemRender,
      onCursorRender: handleCursorRender,
      // onHighlightRender: handleHighlightRender
  }],
    defaultTemplateName: 'syncedCommitteeMember',
    items: props.members
  };

  const isItemInCommittee = () => {
    return props.cursorItemConfig && props.members.find(m => m.uid === props.cursorItemConfig.uid);
  }
 
  return (
    <div className="Committee">
      <p className={`CommitteeTitle ${isItemInCommittee() ? 'selectedCommitteeTitle' : ''}`} onClick={handleTitleClicked}>
        {props.label}
      </p>
      {
        props.openCommitteeId === props.id ? <div className="OrgDiagram pt-4"><OrgDiagram config={config} /></div> : ''
      }
    </div>
  );
}
 
export default Committee;