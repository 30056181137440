import React, { useState, useEffect } from 'react';
import './App.css';
import Suggestor from 'ssuggestor';
import Committee from './Committee';
import { retrieveData, getWindowDimensions } from './utils';

function App() {

  const [cursorItemConfig, setCursorItemConfig] = useState(null);
  const [highlightItemConfig, setHighlightItemConfig] = useState(null);
  const [selectedItemConfigs, setSelectedItemConfigs] = useState([]);
  const [tags, setTags] = useState({});
  const [colors, setColors] = useState({});
  const [committeesData, setCommitteesData] = useState([])
  const [openCommitteeId, setOpenCommitteeId] = useState(null)

  useEffect(() => {
    const localTags = JSON.parse(localStorage.getItem('tags'));
    if (localTags) setTags(localTags);

    const localColors = JSON.parse(localStorage.getItem('colors'));
    if (localColors) setColors(localColors);

    if (committeesData.length === 0) {

      retrieveData()
      .then(data => {
        if (data) {
          setCommitteesData(data)
        }
      })
    }
    }, [committeesData, setCommitteesData])

  let committees = []
  committeesData.forEach(c => {
    committees.push(
      <Committee 
        id={ c.id }
        label={ c.label }
        members={ c.members }
        memberRoles={ c.member_roles }
        setOpenCommitteeId={ setOpenCommitteeId }
        openCommitteeId={ openCommitteeId }
        cursorItemConfig={ cursorItemConfig } 
        setCursorItemConfig={ setCursorItemConfig }
        highlightItemConfig={ highlightItemConfig }
        setHighlightItemConfig={ setHighlightItemConfig }
        selectedItemConfigs={ selectedItemConfigs } 
        setSelectedItemConfigs={ setSelectedItemConfigs }
        tags={ tags }
        setTags={ setTags } 
        colors={ colors }
        setColors={ setColors } 
      />
    )
  })

  const allMembers = {}
  for (let c of committeesData) {
    for (let m of c.members) {
      allMembers[m.uid] = m
    }
  }

  let content;
  let header;
  if (getWindowDimensions().width < 670) {
    content = <p className="block pb-12 px-6 w-full text-center">Sorry, this app is for desktop/tablet use only <span className="inline-block">(◡﹏◡✿)</span></p>
    header = null
  } else {
    content = 
    <>
      <p className="block pb-12 px-6 w-full text-center">Click for selection across committees. Double-click for more options.</p>
      {committees || 'Problems connecting to server...'}
    </>
    const suggestorTheme = {
      activeItem: "rounded-none",
      arrow: "",
      close: "ss-close cursor-pointer",
      input: "w-full px-6 py-2 border rounded-none focus:border-red",
      item: "px-6 py-2 border-b cursor-pointer rounded-none",
      list: "mt-4 w-full bg-white max-h-52 border overflow-clip",
      root: "px-8 py-4 absolute w-full",
    }
    header = 
    <>
      <div className="block w-full pt-3 font-sans">
          <Suggestor list={Object.values(allMembers)} selector={s => s.label} theme={suggestorTheme} onSelect={(value, sug) => { setCursorItemConfig(sug) }} placeholder="Search"/>
      </div>
    </>
  }

 
  return (
    <div className="App">
      { header }
      <p className="block pb-6 pt-32 px-6 w-full text-4xl text-center font-semibold">🏛️ Bundestag Committees</p>
      { content }
    </div>
  );
}
 
export default App;