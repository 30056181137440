import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { photos } from './utils';

export default function Card (props) {
  const color = props.colors[props.itemConfig.uid] || 'rgb(65, 105, 225)'

  const isItemCursored = () => {
    return props.cursorItemConfig && props.itemConfig.uid === props.cursorItemConfig.uid
  }

  const shortenText = (text, chars_n) => {
    if (text && text.length > chars_n) {
      return text.slice(0, chars_n) + '...'
    }
    return text
  }

  const isImportantRole = (role) => {
    return !(role === 'member' || role === 'alternate_member')
  }

  return <div className={`relative block h-full text-left ${isItemCursored() ? 'selectedItem' : ''}`}>
    <div className="relative px-2 py-1 text-white" style={{background: color}}>{shortenText(props.itemConfig.label, 50)}</div>
    <div className="mt-2 mx-2 h-20 flex text-ellipsis overflow-hidden">
      <ProgressiveImage src={props.itemConfig.image} placeholder={photos.a}>
        {(src) => <div className="block w-20 h-20 border bg-cover" style={{backgroundImage: `url(${src})`, backgroundPosition: '50% 50%'}} alt={props.itemConfig.label} />}
      </ProgressiveImage>
      <div className="ml-2 w-36">
        <div className="text-clip text-sm">
          {shortenText(props.itemConfig.occupation, 90)}
        </div>
      </div>
    </div>
    <div className="my-1">
      <span class="bg-gray-100 text-xs font-semibold ml-2 px-2.5 py-0.5">{shortenText(props.itemConfig.party.label, 11)}</span>
      <span class={`bg-gray-100 text-xs font-semibold ml-2 px-2.5 py-0.5 ${isImportantRole(props.committeeRole) ? 'bg-green-100' : ''}`}>{props.committeeRole}</span>
    </div>
  </div>

  // return <div className={`ItemTemplate ${isItemCursored() ? 'selectedItem' : ''}`}>
  //   <div className="ItemTitleBackground" style={{background: color}}>
  //     <div className="ItemTitle">{props.itemConfig.label}</div>
  //   </div>
  //   <div className="ItemPhotoFrame">
  //     <ProgressiveImage src={props.itemConfig.image} placeholder={photos.a}>
  //       {(src) => <img className="ItemPhoto" src={src} alt={props.itemConfig.label} />}
  //     </ProgressiveImage>
  //   </div>
  //   <div className="ItemDescription">{props.itemConfig.party.label} - {props.itemConfig.occupation}</div>
  //   {/* <div className="ItemTags">{tags}</div> */}
  // </div>;
}